<template>
    <div v-frag>
        <div class="container-fluid">
            <div class="row" style="padding-bottom:60px">
                <div class="col-md-2 hidden-sm hidden-xs"></div>
                <div class="col-md-8">
                    <div class="row mt">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <date-picker
                                    v-model="time"
                                    valueType="format"
                                    format="YYYY-MM-DD"
                                    type="format"
                                    placeholder=""
                                    
                                    :disabled-date="(date) => date >= new Date()"
                                    ref="datepicker"
                                ></date-picker>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <select v-model="sport" class="form-control" @change="changeSport">
                                    <option v-for="(sport,i) in menu"  :key="'spn-'+i" :value="sport.id" ><span>{{ $t('sidebar.' + sport.en_name )  }}</span></option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <select v-model="country" class="form-control" :disabled="category.length == 0" @change="changeCat">
                                    <template v-if="category.length > 0">
                                        <option v-for="(cat,i) in category[0].country" :selected="i == 0" :key="'spvn-'+i" :value="cat.id" >
                                            <template v-if="$i18n.locale == 'en'">
                                                {{ iso(cat.name).name }}
                                            </template> 
                                            <template v-if="$i18n.locale == 'fr'">
                                                {{ iso(cat.name).fr_name }}
                                            </template>      
                                        </option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <select v-model="league" class="form-control" id="sel1"  :disabled="leagues.length == 0" @change="changeLeague">
                                    <template v-if="leagues.length > 0">
                                        <option v-for="(league,i) in leagues[0].leagues" :selected="i == 0" :key="'spun-'+i" :value="league.id" >{{ league.name }}</option>
                                    </template>
                                </select>
                            </div>
                        </div>
                    </div>
                    <template v-if="loading">
                        <loader />
                    </template>
                    <template v-else>
                        <div class="gflexx" v-for="(mt,i) in items" :key="`mt${i}`">
                            <div class="top">
                                <span>
                                    <template v-if="$i18n.locale == 'en'">
                                        {{ iso(mt.country).name }},
                                    </template> 
                                    <template v-if="$i18n.locale == 'fr'">
                                        {{ iso(mt.country).fr_name }},
                                    </template>
                                    {{ mt.league }} - {{ $t('feeds.game-id') }}: {{ mt.gameId }}
                                </span>
                                <span>{{ mt.scheduled | moment('D/MM,HH:mm')  }}</span>
                            </div>
                            <div class="rflexx">
                                <div class="">
                                    {{ mt.home }}
                                </div>
                                <div class="scores">
                                    {{ mt.score  }}
                                </div>
                                <div class="">
                                    {{ mt.away }}
                                </div>
                            </div>
                        </div>
                        <div class="text-center" v-if="nextPage > 0">
                            <intersect sentinal-name="fetch-more" @on-intersection-element="onIntersectionElement"/>
                        </div>
                        <div class="text-center pd-top" v-if="items.length == 0">
                            <Alerts type="alert-info" :message=" $t('header.no-results') " />
                        </div>
                    </template>
                </div>
                <div class="col-md-2 hidden-sm hidden-xs"></div>
            </div>
        </div>
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import {mapGetters} from "vuex"
import { getCountryISO2 } from "@/utils/utility";
import ApiService from "@/services/api";
import Alerts from "@/components/sections/betslip-sections/alert";
import intersect from './../sections/feeds/intersect.vue';

export default {
    name :'ResultPage',
    data: () =>({
        loading: false,
        time: null,
        sport:10,
        country:0,
        league:0,
        items:[],
        nextPage:0,
    }),
    watch:{
        time: async function(date) {
            let obj = {
                date:date, 
                sportId:this.sport, 
                countryId:this.country, 
                leagueId:this.league, 
                page:0, 
                size:20
            }
            this.getResult(obj)
        },
    },
    computed:{
        ...mapGetters({
            menu: 'menu',
        }),
        category() {
            return (this.menu.length > 0) ? this.menu.filter( x => x.id == this.sport) : [];
        },
        leagues() {
            return (this.category.length > 0) ? this.category[0].country.filter( x => x.id == this.country) : [];
        }
    },
    components: {
        DatePicker,
        Alerts,
        intersect
    },
    methods:{
        iso: function (code) {
            const res = getCountryISO2(code,'sidebar')
            if(res) {
                return res
            } else {
                return {
                    "code": "FM",
                    "code3": "DEF",
                    "fr_name": code,
                    "name": code,
                    "number": "248"
                }
            }
        },
        getResult: async function(payload) {
            this.loading = true;
            await ApiService.getResults({ ...payload })
            .then( res => {
                this.loading = false;
                const { success,data}  = res.data;
                if(success) {
                    this.items = data.data;
                    this.nextPage = data.nextPage;
                } else {
                    this.items = [];
                    this.nextPage = 0;
                }
                
            })
            .catch( err => {
                this.items = [];
                console.log(err)
            });
        },
        changeSport: function() {

            this.country = 0;
            this.league = 0;

            let obj = {
                date:this.time, 
                sportId:this.sport, 
                countryId:this.country, 
                leagueId:this.league, 
                page:0, 
                size:20
            };

            this.getResult(obj);
        },
        changeCat: async function() {
            
            let obj = {
                date:this.time, 
                sportId:this.sport, 
                countryId:this.country, 
                leagueId:this.league, 
                page:0, 
                size:20
            };
            if(this.country) {
                this.getResult(obj);
            }
        },
        changeLeague: async function() {
            let obj = {
                date:this.time, 
                sportId:this.sport, 
                countryId:this.country, 
                leagueId:this.league, 
                page:0, 
                size:20
            };
            if(this.league) {
                this.getResult(obj);
            }
        },
        onIntersectionElement: async function(value) {
        // value will be true when the component is visible on the page otherwise false
        // you can do whatever you want
        if(value) {
            let obj = {
                date:this.time, 
                sportId:this.sport, 
                countryId:this.country, 
                leagueId:this.league, 
                page: this.nextPage, 
                size:20
            };

            await ApiService.getResults({ ...obj })
            .then( res => {
                this.loading = false;
                const { success,data}  = res.data;
                if(success) {
                    if(Array.isArray(data.data)) {
                        data.data.forEach(el => {
                            this.items.push(el)
                        });
                    }

                    if(data.data.length > 0) {
                        this.nextPage = data.nextPage;
                    }  
                } else {
                    this.items = [];
                    this.nextPage = 0;
                }
                
            })
            .catch( err => {
                this.items = [];
                console.log(err)
            });
        }
      },
    },
    mounted() {
        this.time = this.$moment().format('YYYY-MM-DD');
        this.$store.dispatch("fetch_sidebar_menu");

        
    },
}
</script>

<style scoped>
.mx-datepicker {
    width: 100%;
}
.mt {
    margin-top: 15px;
}
.gflexx {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    background-color: var(--bg-dark-medium);
    border-radius: 5px;
    margin-bottom: 5px;
    font-weight: 500;
}
.gflexx .top {
    color: var(--light-black);
    font-weight: normal;
}
.rflexx {
    display: grid;
    grid-template-columns: 1fr 140px 1fr;
}
.rflexx .scores {
    color: var(--bg-orange);
    display: block;
    padding: 2px 40px;
    border-radius: 20px;
    height: 20px;
}
.rflexx div:last-child {
    text-align: right;
}
.top  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
}

</style>